.block-programs-item:first-child {
  margin: 0;
}
.page-programs .playlist-container {
  margin: 64px 0 0 0;
}
.page-programs .playlist-container:first-child {
  margin: 0;
}

@media (max-width: 1200px) {
  /* .page-programs .banner-container {
    margin: 25px auto 0 auto;
  } */
  .page-programs .playlist-container {
    margin: 15px 0 0;
  }
}
