.preview-program-container__row-title {
  display: flex;
  align-items: center;
  margin: 0 0 32px 0;
}
.preview-program-container__title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 37px;
  color: #000000;
  margin: 0 0 0 18px;
}
.preview-program-container__button {
  width: 100%;
  height: 60px;
  background-color: #f1f3f8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 25px 0 0 0;
}
.preview-program-container__button_text {
  margin: 0 15px 0 0;
}

@media (max-width:1200px) {
  .preview-program-container__row-title {
    margin: 24px 0 16px 0;
  }
}
