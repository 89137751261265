.preview-program {
  position: relative;
  cursor: pointer;
  width: 471px;
  /* height: 218px; */
  border-radius: 5px;
}
.preview-program a {
  display: flex;
}
.preview-program img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.preview-program__inner-row {
  position: absolute;
  bottom: 0;
  overflow: hidden;
  width: 100%;
}
.preview-program__title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #ffe500;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.preview-program__description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  margin: 5px 0 0 0;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.preview-program__timetable {
  border: 1px solid #ffffff;
  color: #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 6px 0;
}
.preview-program__inner-row-content {
  display: none;
  justify-content: space-between;
  align-items: center;
  height: 88px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
  padding: 0 18px;
}
.preview-program__timetable {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1199px) {
  /* .preview-program__inner-row-content {
    display: flex;
  } */
}

/* @media (max-width: 1880px) and (min-width: 1200px) { */
  .preview-program-container__elements .preview-program {
    width: 100%;
  }
/* } */

@media (max-width: 991px) {
  .programs-screen__title{
    font-size: 36px !important;
  }
  .preview-program{
    width: 100%;
  }
}