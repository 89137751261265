.block-emcees {
  width: 100%;
  height: 318px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.block-emcees__flipper {
  width: 100%;
  height: 100%;
}
.block-emcees__front .block-emcees__info-container {
  position: absolute;
  height: 100%;
  width: 275px;
  display: flex;
  align-items: center;
}

.block-emcees__back .block-emcees__info-container {
  position: absolute;
  height: 100%;
  width: 275px;
  margin: 20px;
}
.block-emcees__name {
  position: absolute;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  color: #ffffff;
  margin: 0 0 0 78px;
  max-width: 220px;
  text-align: start;
}
.block-emcees__description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 127.19%;
  color: #ffffff;
}
.block-emcees__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.block-emcees__back-side .filter {
  padding: 20px;
  width: 40%;
  position: absolute;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 127.19%;
  color: #ffffff;
}
.block-emcees__flipper {
  transition: 0.6s;
  transform-style: preserve-3d;

  position: relative;
}
.block-emcees__front,
.block-emcees__back {
  border-radius: 5px;
  overflow: hidden;
  backface-visibility: hidden;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
}
.block-emcees__front {
  width: 100%;
  z-index: 2;
}
.block-emcees__back {
  width: 100%;
  transform: rotateY(180deg);
}
.block-emcees {
  perspective: 2000px;
  position: relative;
}
.block-emcees__programs-title {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #FFE500;
}
.block-emcees__programs-item {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
}
.block-emcees__back {
  transform: rotateX(180deg);
}
.block-emcees .block-emcees__flipper {
  transform-origin: 100% 159px;
}
.block-emcees.active .block-emcees__flipper {
  transform: rotateX(-180deg);
}.emcee-item:not(:first-child) {
  margin: 36px 0 0;
}
.emcee-item:first-child {
  margin: 0;
}

@media (max-width: 1200px) {
  .block-emcees__name {
    font-size: 24px;
    line-height: 28px;
    margin: 0 0 0 28px;
    word-spacing:9999px;
  }
  .block-emcees__back .block-emcees__info-container {
    margin: 8px;
    width: 65%;
  }
  .block-emcees__description {
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
  }
  .block-emcees__programs-title {
    margin: 8px 0 2px 0;

  }
  .block-emcees__programs-item {
    margin-bottom: 2px;
    margin-top: 0;
  }
  .emcee-item:not(:first-child) {
    margin: 12px 0 0;
  }
}
