.emcee-item {
  margin: 0 0 12px 0;
}

.block-emcees.active {
  background: unset;
}
.emcees-row {
  display: flex;
  height: 68px;
}
.emcees-row__icon {
  width: 38px;
  height: 38px;
}
.emcees-row__title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 37px;
  color: #000000;
  margin: 0 0 0 18px;
}

.page-emcees .small-column__chart .playlist-container:not(:first-child) {
  margin: 64px 0 0;
}

@media (max-width: 1200px) {
  .emcees-row {
    height: unset;
    margin-top: 24px;
  }
  .page-emcees .small-column__chart .playlist-container:not(:first-child) {
    margin: 15px 0 0;
  }
}
