.block-programs__row {
  display: flex;
  align-items: center;
  margin: 0 0 32px;
  position: relative;
}
.block-programs__title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 37px;
  color: #000000;
  margin: 0 0 0 18px;
}
.block-programs__scroll-bar {
  display: flex;
  flex-wrap: wrap;
  /* max-height: 1137px;
  overflow: hidden;
  overflow-y: auto;
  padding: 0 20px 0 0; */
}
.block-programs__scroll-bar {
  scrollbar-color: #7500ab #ffffff;
  scrollbar-width: thin;
}
.block-programs__scroll-bar::-webkit-scrollbar {
  width: 5px;
  background-color: #ffffff;
}

.block-programs__scroll-bar::-webkit-scrollbar-track {
  border: 2px solid #ffffff;
  background-color: #e3ccee;
}
.block-programs__scroll-bar::-webkit-scrollbar-thumb {
  background-color: #7500ab;
  border-radius: 10px;
}
@media (max-width: 1200px) {
  .block-programs__row {
    padding: 0;
    margin-bottom: 12px;
  }
}
