.news-detail__date {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #7f7f7f;
}

.news-detail__title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 32px;
  color: #7500ab;
  margin: 12px 0 0 0;
}
.news-detail__headline {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  margin: 12px 0 0 0;
}
.news-detail__content {
  margin: 12px 0 0 0;
}
.news-detail__content p {
  margin: 5px 0;
}
.news-detail__content .ql-video__content {
  height: auto;
}
.news-detail__content img {
  width: 100%;
}
.page-news-detail .playlist-container {
  margin: 64px 0 0 0;
}
.page-news-detail .playlist-container:first-child {
  margin: 0;
}
.page-news-detail .banner-container {
  /* padding: 0 25px; */
  margin: 64px 0 0 0;
}
.page-news-detail .button-back {
  width: 100%;
  height: 60px;
  background-color: #f1f3f8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 25px 0 0 0;
}
.page-news-detail .button-back__text {
  margin: 0 15px 0 0;
}
.page-news-detail .letest-news {
  margin: 30px 0 0 0;
}
.letest-news__items {
  display: flex;
  margin: 22px 0 0 0;
}
.page-news-detail .letest-news__items .item-news {
  margin: 0;
  width: 100%;
}
.page-news-detail .letest-news__items a:first-child {
  margin: 0 10px 0 0;
}
.page-news-detail .letest-news__items a:last-child {
  margin: 0 0 0 10px;
}

@media (max-width: 1200px) {
  .page-news-detail .playlist-container {
    margin-top: 24px;
  }
  /* .page-news-detail .banner-container {
    margin: 25px auto 0 auto;
  } */
}
