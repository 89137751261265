.header-container {
  height: 160px;
  position: relative;
}

@media (max-width: 1200px) {
  .header-container {
    height: 68px;
  }
}
.header {
  position: absolute;
  /* position: fixed; */
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #ffffff;
  z-index: 3;
  /* border-bottom: 1px solid #e5e9f2; */
}

.header.scroll-nav {
  /* position: static; */
  /* position: fixed; */
}

.header-row {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 50px;
  overflow: hidden;
  z-index: 3;
}

.header-logo,
.header-logo.scroll-nav {
  height: 50px;
  max-width: none;
}

.scroll-menu {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 80px;
  /* position: relative; */
  margin: 0 0 0 24px;
}

.header-players {
  display: flex;
  width: 100%;
  min-height: 80px;
  transition: all 300ms ease 0s;
}

.header-players.scroll-nav {
  margin-top: -80px;
}

.header-nav {
  height: 80px;
}

.header-bar {
  display: flex;
  height: 100%;
}

.header-ether__audio,
.header-ether__video {
  display: flex;
  padding: 0 0 0 130px;
  align-items: center;
  width: 100%;
  height: 100%;
}
.header-ether__video {
  justify-content: flex-end;
}
.nav-item.scroll-nav {
  font-size: 20px;
}

.nav-item {
  display: flex;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  color: #000000;
  text-decoration: none;
}

.nav-item:hover {
  background-color: #7500ab;
  color: #ffffff;
  transition-duration: 0.4s;
}

.nav-item_text {
  display: flex;
  text-decoration: none;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.navigation .nav-item {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
}

.nav-item_active {
  background-color: #7500ab;
  color: #ffffff;
  transition-duration: 0.4s;
}

.navigation {
  position: absolute;
  display: flex;
  /*width: 100%;*/
  /*max-width: 1398px;*/
  width: 100%;
  height: 80px;
  background-color: #ffffff;
  z-index: 3;
  /* transition: all 300ms ease 0s; */
  top: 80px;
}

.navigation.scroll-nav {
  top: 0;
  position: fixed;
}

.header-burger {
  display: none;
}

@media (min-width:1200px) {
  .header, .navigation {
    width: 1050px;
  }
}

@media (min-width:1280px) {
  .header, .navigation {
    width: 1168px;
  }
}

@media (min-width:1440px) {
  .header, .navigation {
    width: 1344px;
  }
}

/* @media (min-width: 1200px) {
  .header {
    width: 70vw;
  }
} */

@media (max-width: 1200px) {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 970px;
    height: 68px;
    box-shadow: 0 4px 45px rgba(0,0,0,.5);
    z-index: 4;
    top: 0;
    /* position: fixed; */
  }
  .header.scroll-nav {
    position: fixed;
  }
  .header.active-burger {
    z-index: 10001;
  }

  .header-logo,
  .header-logo.scroll-nav {
    width: 160px;
  }

  .header-burger {
    cursor: pointer;
    display: block;
    position: relative;
    width: 30px;
    height: 20px;
    z-index: 11;
    right: 50px;
  }

  .header-row {
    padding: 10px 0 10px 50px;
    height: 100%;
  }

  .header-players {
    display: none;
  }

  .scroll-menu {
    width: 0;
    height: 0;
    margin: 0;
  }

  .navigation {
    display: none;
  }

  .header-bar {
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .9);
    /* opacity: 0.9; */
    z-index: 11;
    margin: 0;
    border: none;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease 0s;
  }

  .header-bar.active-burger {
    top: 0;
    backdrop-filter: blur(6px);
  }

  .nav-item {
    color: #ffffff;
  }

  .header-players {
    display: none;
  }

  .header-burger.active-burger::before {
    transform: rotate(45deg);
    margin: 9px 0 0 0;
    background-color: #fff;
  }
  .header-burger.active-burger:after {
    transform: rotate(-45deg);
    margin: 0 0 9px 0;
    background-color: #fff;
  }
  .header-burger.active-burger span {
    transform: scale(0);
  }

  .header-burger:before,
  .header-burger:after {
    content: '';
    background-color: #7500ab;
    width: 100%;
    height: 3px;
    position: absolute;
    left: 0;
    transition: all 0.3s ease 0s;
  }

  .header-burger span {
    background-color: #7500ab;
    width: 100%;
    height: 3px;
    position: absolute;
    left: 0;
    top: 9px;
    transition: all 0.3s ease 0s;
    z-index: 99;
  }
  .header-burger:before {
    top: 0;
  }

  .header-burger:after {
    bottom: 0;
  }
}

@media (max-width: 1800px) and (min-width: 1200px) {
  .header-logo, .header-logo.scroll-nav {
    height: 35px;
  }
  .ether-player__logo-container svg{
    width: 54px;
  }
  .header-ether__audio, .header-ether__video{
    padding: 0 0 0 150px;
  }
  /* .header-ether__video .ether-player__info{
    font-size: 12px;
  } */
  .navigation .nav-item{
    font-size: 18px;
  }
  .nav-item.scroll-nav {
    font-size: 14px;
  }
}

@media (max-width: 1400px) {
  .navigation .nav-item {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .header {
    max-width: 750px;
  }
}

@media (max-width: 991px) {
  .nav-item {
    height: 70px;
    flex: none;
  }
}
@media (max-width: 768px) {
  .header {
    max-width: none;
  }
  .header-row {
    padding: 10px;
  }
  .header-burger {
    right: 10px;
  }
}

@media (max-height: 550px) {
  .header-row {
    padding: 10px 0 10px 12px;
  }
  .header-burger {
    right: 12px;
  }
}