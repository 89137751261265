.disable {
  opacity: 0.25;
}

.arrow-green .circul {
  fill: #ffffff;
  stroke: #46c31e;
}

.arrow-green .arrow {
  fill: #46c31e;
}

.arrow-green:not(.disable):hover .circul,
.arrow-green__filled .circul {
  fill: #46c31e;
  stroke: #46c31e;
}

.arrow-green:not(.disable):hover .arrow,
.arrow-green__filled .arrow {
  fill: #ffffff;
}

.arrow-yellow .circul {
  fill: none;
  stroke: #ffe500;
}

.arrow-yellow .arrow {
  fill: #ffe500;
}

.arrow-yellow:not(.disable):hover .circul,
.arrow-yellow__filled .circul {
  fill: #ffe500;
  stroke: #ffe500;
}

.arrow-yellow:not(.disable):hover .arrow,
.arrow-yellow__filled .arrow {
  fill: #000000;
}

.arrow-purple .circul {
  fill: #ffffff;
  stroke: #ffffff;
}

.arrow-purple .arrow {
  fill: #7500ab;
}

.arrow-purple:not(.disable):hover .circul,
.arrow-purple__filled .circul {
  fill: #7500ab;
  stroke: #ffffff;
}

.arrow-purple:not(.disable):hover .arrow,
.arrow-purple__filled .arrow {
  fill: #ffffff;
}
