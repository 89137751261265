.archive-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 32px 0;
  position: relative;
}
.archive-row__title-container {
  display: flex;
  align-items: center;
}

.archive-row__title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 37px;
  color: #000000;
  margin: 0 0 0 18px;
}

.archive-row__link-to-back {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #7500ab;
}

.archive-row__link-to-back:hover {
  color: #46c31e;
}

.archive-row__date-container {
  display: flex;
}
.archive-offers-content .button-back {
  margin: 0  0 25px;
}

/* .archive__scroll-bar {
  height: 964px;
  overflow: hidden;
  overflow-y: scroll;
  padding: 0 30px 0 0;
} */

.archive__scroll-bar {
  scrollbar-color: #7500ab #ffffff;
  scrollbar-width: thin;
}
.archive__scroll-bar::-webkit-scrollbar {
  width: 5px;
  background-color: #ffffff;
}

.archive__scroll-bar::-webkit-scrollbar-track {
  border: 2px solid #ffffff;
  background-color: #e3ccee;
}
.archive__scroll-bar::-webkit-scrollbar-thumb {
  background-color: #7500ab;
  border-radius: 10px;
}

@media (max-width: 1200px) {
  .archive-row {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
  }
  .archive-row__link-to-back {
    margin: 10px 0;
  }
  .archive__scroll-bar {
    height: unset;
    overflow: unset;
  }
}

@media (max-width: 991px) {
  .archive__scroll-bar {
    padding: 0;
  }
}

@media (max-width: 768px) {
}
