.programs-detail__title {
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  margin: 0 0 20px;
}
.programs-detail__title.add-margin {
  margin: 50px 0 20px;
}
.programs-screen {
  position: relative;
  width: 100%;
}
.programs-screen img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.programs-screen__info-row {
  position: absolute;
  bottom: 0;
  /* height: 230px; */
  padding: 130px 0 30px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
}
.programs-screen__row-content {
  padding: 0 35px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.page-programs-detail .block-podcasts__dropdown-container{ display: none; }
.programs-screen__title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 66px;
  line-height: 77px;
  letter-spacing: 0.015em;
  color: #ffe500;
  padding: 15px 0 0 0;
}
.programs-screen__short-timetable {
  /* position: absolute; */
  bottom: 30px;
  right: 30px;
  width: 300px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 15px 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.preview-program {
  margin: 25px 0 0 0;
}
.preview-program:first-child {
  margin: 0;
}
.programs-screen__title_mobile, .programs-screen__short-timetable_mobile {
  display: none;
}

.program-detail__emcees-list {
  display: flex;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px;
}

.program-detail__emcees-list p {
  margin: 0;
}

.page-programs-detail .block-podcasts {
  margin-top: 50px;
}

.page-programs-detail .program-detail_emcees-header {
  font-size: 32px;
  line-height: 36px;
  /* margin: 12px 0 6px; */
  font-weight: 500;
  color: #7600AC;
  margin: 0;
}

@media (max-width: 1200px) {
  .programs-screen {
    padding: 0 12px;
    background: #fff;
    width: calc(100% - 24px);
  }
  .page-programs-detail {
    margin-top: 12px;
  }
  .page-programs-detail .content-block {
    margin-top: 0;
  }
  .programs-screen__title_mobile, .programs-screen__short-timetable_mobile {
    display: flex;
  }
  .programs-screen__title, .programs-screen__short-timetable {
    display: none
  }
  .programs-screen__short-timetable_mobile {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
    line-height: 16px;
    color: #78797C;
  }
  .programs-screen__title_mobile {
    margin-top: 8px;
    margin-bottom: 12px;
    font-size: 32px;
    line-height: 38px;
    color: #7600AC;
  }
  .programs-screen__info-row {
    bottom: 0;
    left: 12px;
    width: calc(100% - 24px);
    /* height: 195px; */
    padding: 30px 0 30px;
  }

  .page-programs-detail .program-detail_emcees-header {
    font-size: 18px;
    line-height: 24px;
    /* margin: 12px 0 6px; */
  }
}
