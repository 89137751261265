.page-novelty .playlist-container {
  margin: 64px 0 0 0;
}
.page-novelty .playlist-container:first-child {
  margin: 0;
}
.page-novelty .big-column__chart .chart-scroll {
  display: none;
}

.page-novelty .small-column__chart .flyaway-chart .chart-item__other-info,
.page-novelty .small-column__chart .flyaway-chart .chart-item__number {
  display: none;
}

.page-novelty .small-column__chart .flyaway-chart .chart-item__info, 
.page-novelty .small-column__chart .flyaway-chart .track-info {
  width: 100%;
} 

@media (max-width: 1199px) {
  .page-novelty .playlist-container {
    margin: 15px 0 0;
  }
}
