.ql__pictures-container {
  display: flex;
  flex-wrap: wrap;
}
.ql__picture-item {
  width: 100px;
  height: 100px;
  cursor: pointer;
  padding: 5px;
}

.ql__picture-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ql-tooltip.ql-editing {
  left: 0 !important;
}

.ql-video__content {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 56%;}

.ql-video__content iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}