.page-news .playlist-container {
  margin: 64px 0 0 0;
}
.page-news .block-news__scroll-bar {
  flex-wrap: wrap;
  align-items: center;
}
.page-news .block-news__scroll-bar a {
  display: flex;
  /* flex: 1;
  min-width: 250px;
  max-width: 50%; */
  width: calc(50% - 10px);
  /* margin: 0 20px 20px 0; */
}
.page-news .block-news__scroll-bar a:nth-child(2n) {
  margin: 0 0 20px 10px;
}
.page-news .block-news__scroll-bar a:nth-child(2n + 1) {
  margin: 0 10px 20px 0;
}
/* .page-news .block-news__scroll-bar a {
  width: 50%;
} */
.page-news .playlist-container:first-child {
  margin: 0;
}
.page-news .item-news {
  width: 100%;
  /* min-width: 300px; */
}
.page-news .banner-container {
  /* padding: 0 25px; */
  margin: 66px 0 0;
}

@media (max-width: 1440px) {
  .page-news .block-news__scroll-bar a {
    display: flex;
    /* flex: 1;
    min-width: 100%; */
  }
}
@media (max-width: 1200px) {
  .item-news__row-date {
    display: none;
  }
  .page-news .block-news__scroll-bar a:nth-child(2n), .page-news .block-news__scroll-bar a:nth-child(2n + 1) {
    width: 100%;
    margin: 0 0 12px;
  }
  .page-news .block-news__scroll-bar a:last-of-type {
    margin: 0;
  }
  /* .page-news .banner-container {
    margin: 25px auto 0 auto;
  } */
  .page-news .playlist-container {
    margin: 15px 0 0;
  }
}

@media (max-width: 640px) {
  .item-news__row-content {
    padding: 8px;
  }

}
