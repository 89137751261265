.slick-slider {
  position: relative;
}

.slide-info {
  position: absolute;
  bottom: 0;
  width: calc(100% - 40px);
  padding: 20px 20px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
}

.slider-title {
  /*display: flex;*/
  /*position: absolute;*/
  position: absolute;
  top: 32px;
  left: 32px;
  background-color: #ffe500;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  padding: 8px 18px;
  white-space: nowrap;
  /*top: 32px;*/
  /*left: 32px;*/
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;
}

.slide-item {
  height: 335px;
  outline: none;
  overflow: hidden;
  position: relative;
  z-index: 20;
  border-radius: 5px;
}

.slick-list {
  position: relative;
  overflow: hidden;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  /*cursor: hand;*/
}

.slick-title {
  margin: 7px 0 0 0;
  max-height: 42px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  width: calc(100% - 187px);
  display: inline-block;
  white-space: nowrap;
}

.slick-date {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffe500;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  cursor: pointer;
  bottom: 0;
  right: 0;
}
.slick-arrow {
  z-index: 2;
}
.slick-active svg rect {
  stroke: #ffe500;
  fill: #ffe500;
}

.slick-dots svg:hover rect {
  stroke: #ffe500;
  fill: #ffe500;
}

.slick-prev {
  right: 165px;
  bottom: 30px;
}

.slick-next {
  right: 25px;
  bottom: 30px;
}

.slick-dots {
  position: absolute;
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  bottom: 37px;
  right: 50px;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  display: block;
  padding: 0;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
}
.slick-slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media (min-width: 1440px) {
  .slide-item {
    height: 472px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1440px) {
  .slide-item {
    height: 398px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1279px) {
  .slide-item {
    height: 349px;
  }
}
@media (max-width: 1200px) {
  .slick-date {
    display: none;
  }
}
@media (max-width: 991px) {
  .slick-slide img {
    object-fit: cover;
  }
  .slider-title {
    font-size: 14px;
    line-height: 18px;
    top: 16px;
    left: 16px;
    padding: 8px;
    font-weight: 500;
    /* border-radius: 70px; */
  }
  .slick-next {
    right: 10px;
    bottom: 10px;
  }
  .slick-dots {
    bottom: 17px;
    right: 40px;
  }
  .slick-prev {
    right: 135px;
    bottom: 10px;
  }
  .slide-info {
    padding: 10px 16px 10px;
  }
}
.slick-slide {
  position: relative;
  transition: transform 0.3s;
}
@media (max-width: 480px) {
  .slide-item {
    height: 200px;
  }
}