/* html {
  overflow-y: scroll;
} */
::-webkit-scrollbar {
  width: 5px; /* ширина для вертикального скролла */
  height: 8px; /* высота для горизонтального скролла */
  background-color: rgba(255,255,255, 0);
  position: absolute;
  right: 0;
}
.non-scroll {
  overflow: hidden;
  height: 100vh;
}
/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 9em;
  /* box-shadow: inset 1px 1px 10px #f3faf7; */
  position: absolute;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #333;
}

/* Стрелки */

::-webkit-scrollbar-button:vertical:start:decrement {
 display: none;
}

::-webkit-scrollbar-button:vertical:end:increment {
  display: none;
}

::-webkit-scrollbar-button:horizontal:start:decrement {
 display: none;
}

::-webkit-scrollbar-button:horizontal:end:increment {
  display: none;
}

.wrapper {
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background-size: cover;
  background-attachment: fixed;
}

.container {
  width: 100%;
  position: relative;
}

.main {
  padding: 0px 0 80px 0;
}
.big-column,
.big-column__chart {
  display: flex;
  flex-direction: column;
}

.big-column {
  width: 758px;
}

.big-column__chart {
  width: 56%;
}

.small-column,
.small-column__chart{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.inline-row{
  display: flex;
}

.small-column {
  width: 472px;
}

.small-column__chart {
  width: calc(42% - 50px);
}

/* Video Stream */
.videostream-dark-layout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  background-color: rgba(0,0,0,0.8);
  justify-content: center;
  align-items: center;
}
.videostream-container {
  position: fixed;
  width: 80vw;
  z-index: 100000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.videostream-close-button {
  position: absolute;
  z-index: 1;
  right: 0;
  transform: translate(100%, -100%);
  cursor: pointer;
}
.videostream-close-button svg {
  width: 27px;
  fill: #0855f2;
}

@media (min-width: 1200px) {
  .container {
    width: 1050px;
  }
}

@media (min-width:1280px) {
  .container {
    width: 1168px;
  }
}

@media (min-width:1440px) {
  .container {
    width: 1344px;
  }
}

@media (max-width: 1900px) {
  .container {
    /*zoom: 90%;*/
  }
}

@media (max-width: 1600px) {
  .container {
    /*max-width: 80vw;*/
    /*zoom: 80%;*/
  }
}

@media (max-width: 1440px) {
    
}

@media (max-width: 1200px) {
  .container {
    /*zoom: 100%;*/
    max-width: 970px;
  }

  .main {
    padding: 0px 0;
  }
  .big-column,
  .small-column,
  .big-column__chart,
  .small-column__chart {
    max-width: 100%;
    min-width: 100%;
  }

  .footer-player {
    bottom: 0;
  }

  .content-block.footer-block {
    flex-direction: column;
    align-items: center;
    max-height: unset;
  }
  .content-block.footer-block .big-column {
    width: 100%;
  }
  .small-column, .small-column__chart {
    margin-top: 15px;
    /* flex-direction: row;
    justify-content: center; */
  }
}

@media (max-width: 991px) {
  .container {
    max-width: 750px;
  }

  /* .small-column__chart {
    margin: 50px 0 0 0;
  } */
}

@media (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}

#progress {
  border: 1px solid black;
  cursor: pointer;
}

#bar {
  height: 20px;
  background-color: green;
  width: 0;
}


/* @media (max-width: 1880px) and (min-width: 1200px) { */
  .big-column {
    width: 56%;
  }
  .small-column{
    width: calc(42% - 50px);
  }
  /* .block-podcasts,   
  .block-news,
  .block-programs,
  .emcees-content,
  .aboutpage-content,
  .program-detail-content,
  .news-detail-content,
  .current-offer__container,
  .form-mailing__container,
  .archive-offers-content,
  .archive-row {
    padding-right: 37px!important;
  }
  .block-programs {
    padding-left: 25px !important;
  } */
  /* .block-podcasts {
    padding-left: 25px;
  } */
  /* .preview-program-container,
  .banner-container {
    padding-left: 13px !important;
    padding-right: 25px !important;
  } */
  .banner-container {
    max-width: 472px;
  }
  .banner-container:first-child {
    margin-top: 0 !important;
  }
  /* .banner-container {
    margin: 64px 0px 0px !important;
  } */

  .form-mailing__container {
    width: 100% !important;
    display: flex !important;
  }

/* } */

/* @media (max-width: 1880px) and (min-width: 1440px) {
  .right-banner{
    right: calc(16vw - 61px);
  }
} */

/* @media (min-width: 1200px) and (max-width: 1439px) {
  .right-banner{
    right: calc(15vw - 61px);
  }
}
@media (min-width: 1440) and (max-width: 1599) {
  .right-banner{
    right: calc(15vw - 61px);
  }
}
@media (min-width: 1600) and (max-width: 1919) {
  .right-banner{
    right: calc(15vw - 61px);
  }
}
@media (min-width: 1920px) {
  .right-banner{
    right: calc(15vw - 62px);
  }
} */
.button-back {
  width: 100%;
  height: 60px;
  background-color: #f1f3f8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 25px 0 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: #000;
}
.button-back__text {
  margin: 0 6px 0 0;
}
.preview-program-container {
  width: 100%;
}

@media (max-width: 1200px) { 
  .block-podcasts, 
  .block-news,
  .block-programs,
  .emcees-content,
  .aboutpage-content,
  .program-detail-content,
  .news-detail-content,
  .current-offer__container,
  .form-mailing__container,
  .archive-offers-content,
  .archive-row,
  .slider-padding {
    padding: 0 !important;
    margin-top: 24px !important;
  } 
  .banner-container, 
  .preview-program-container {
    width: 100%;
    padding: 0 !important;
  }
  /* .banner-container, .banner-container:first-child {
    margin-top: 24px !important;
  } */
  .button-back {
    margin: 12px 0 0 !important;
  }
  /* .small-column {
    margin-top: 24px !important;
  } */
}
.playlist-container {
  width: 100%;
}
.playlist-container-item:not(:first-child) {
  margin-top: 12px;
}
.chart-block.playlist .chart-item__content {
  border-radius: 5px;
}

@media (max-width: 991px) {
  .mob-column {
    display: flex;
    align-items: normal !important;
    flex-direction: column;
  }
  .mob-column .block-podcasts__dropdown-container,
  .mob-column .block-news__dropdown-container{
    margin: 10px 0 0 0;
  }
}


