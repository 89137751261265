.dropdown-menu__container {
  display: flex;
  align-items: center;
  margin: 0 0 0 24px;
  position: relative;
  justify-content: center;
}
.dropdown-menu__container:first-child {
  margin: 0;
}
.dropdown-menu__title {
  color: #7f7f7f;
  margin: 0 12px 0 0;
  z-index: 2;
}
.dropdown-menu__svg-container {
  cursor: pointer;
  display: flex;
  transition: all 0.6s ease 0s;
  z-index: 2;
}
.dropdown-menu__scroll-bar {
  position: absolute;
  /* max-height: 250px; */
  overflow-x: scroll;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  top: -10px;
  /* min-width: 110px; */
  /* padding: 40px 10px 10px 0; */
  transition: all 0.6s ease 0s;
  z-index: 1;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: dropdown;
}
.dropdown-menu__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* overflow-y: scroll; */
  padding: 0;
}
.dropdown-menu__list::-webkit-scrollbar {
  width: 5px;
  background-color: #ffffff;
}
.dropdown-menu__list::-webkit-scrollbar-track {
  border: 2px solid #ffffff;
  background-color: #ffe500;
}
.dropdown-menu__list::-webkit-scrollbar-thumb {
  background-color: #ffe500;
  border-radius: 10px;
}
.dropdown-menu__list {
  scrollbar-color: #ffe500 #ffffff;
  scrollbar-width: thin;
}
.dropdown-menu__item {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 6px 0;
  cursor: pointer;
  width: 100%;
}
.dropdown-menu__item:hover {
  background-color: #fef5a3;
  transition: all 0.4s ease;
}
.dropdown-menu__item.active {
  background-color: #ffe500;
}
@keyframes dropdown {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
