.page-podcasts .small-column__chart .playlist-container:not(:first-child) {
  margin: 64px 0 0;
}

/* .page-podcasts .footer-block {
  max-height: 335px;
} */

@media (max-width: 991px) {
  .page-podcasts .footer-block {
    max-height: none;
  }
}

@media (max-width: 1199px) {
  .page-podcasts .small-column__chart .playlist-container:not(:first-child){
    margin: 15px 0 0;
  }
}
