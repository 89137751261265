.banner-container {
  width: 100%;
  margin-top: 64px;
}
.footer-block .banner-container, .local.banner-container:first-child {
  margin-top: 0;
}
.banner-container:not(.local) {
  position:relative;
}
.banner-container:not(.local)::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.banner-link {
  width: 100%;
  height: 100%;
}
.banner-container:not(.local) .banner-link {
  position: absolute;
}
.banner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* border: 1px solid #e5e9f2; */
  background-position: center;
  background-size: auto 100%;
  overflow: hidden;
  border-radius: 5px;
}
.banner__img {
  width: auto;
  height: 100%;
  object-fit: cover;
}

.banner a {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.banner__title {
  display: flex;
  position: absolute;
  background-color: #ffe500;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  padding: 8px 18px;
  white-space: nowrap;
  top: 32px;
  left: 32px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;
}

.banner-zoom {
  transform: translate(0, 0) scale(1.01) translateZ(0);
  transition: transform 0.5s ease-out;
}

.banner:hover .banner-zoom {
  transform: translate(0, 0) scale(1.08);
  transition: transform 0.8s cubic-bezier(0.375, 0.435, 0.41, 0.75);
  /* transform: scale(1.1, 1.1); */
}

.banner-filter {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #7500ab;
  opacity: 0;
  transition: all 1.5s ease-out;
}

.banner:hover .banner-filter {
  opacity: 0.8;
}

@media (max-width:991px) {
  .banner__title {
    font-size: 14px;
    line-height: 18px;
    top: 16px;
    left: 16px;
  }
}

@media (max-width: 1200px) {
  .banner__img {
    width: 100%;
  }
  .footer-block .banner-container {
    margin-top: 0;
  }
  .local.banner-container, .banner-container {
    margin: 39px auto 0;
  }
}
/* @media (max-width: 480px) {
  .banner-container {
    width: 100%;
    height: 358px;
  }
} */
