.chart-position {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-left: 20px; */
  
}
.chart-position__number {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
}
.admin-chart .chart-position__number {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
}
