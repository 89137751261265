.chart-item {
  display: flex;
  position: relative;
  width: 100%;
  /* height: 68px; */
  margin: 12px 0 0 0;
  border-radius: 5px;
  cursor: pointer;
}

.new_songs .chart-item {
  width: calc(100% - 30px);
}
.chart-item.no-audio {
  pointer-events: none !important;
  opacity: .6;
}

.chart-item.green:hover .chart-item__content,
.chart-item.green:hover .chart-item__number,
.chart-item.green:hover + .chart-item__like {
  background-color: #daf3d2;
  transition: all 0.4s ease;
  /* box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1); */
}

.chart-item.green.active .chart-item__content,
.chart-item.green.active .chart-item__number {
  background-color: #60c427;
  color: #ffffff;
}

.chart-item.yellow:hover .chart-item__content,
.chart-item.yellow:hover .chart-item__number,
.chart-item.yellow:hover + .chart-item__like {
  background-color: #fef5a3;
  transition: all 0.4s ease;
  /* box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1); */
}

.chart-item.yellow.active .chart-item__content,
.chart-item.yellow.active .chart-item__number {
  background-color: #ffe500;
}

.chart-item.yellow.active .track-info__author {
  color: #000000;
}

.chart-item.purple:hover .chart-item__content,
.chart-item.purple:hover .chart-item__number {
  background-color: #bba4d5;
  transition: all 0.4s ease;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
}

.chart-item.purple.active .chart-item__content,
.chart-item.purple.active .chart-item__number {
  background-color: #7500ab;
  color: #ffffff;
}

.chart-item.green.active .track-info__author,
.chart-item.green.active .chart-item__date,
.chart-item.purple.active .track-info__author,
.chart-item.purple.active .chart-item__date {
  color: #ffffff;
}

.chart-item.purple.active .line,
.chart-item.green.active .line {
  stroke: #7f7f7f;;
}

.chart-item.active .chart-item__position path {
  fill: #ffffff;
}

.chart-item__content {
  display: flex;
  width: 100%;
  /* border-radius: 5px; */
}

.chart-item__content.white {
  background-color: #ffffff;
}
.chart-item__content.grey {
  background-color: #f1f3f8;
}

.chart-item__preview-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-item__control {
  position: absolute;
  display: flex;
  width: 47px;
  height: 47px;
  justify-content: center;
  align-items: center;
}

.chart-item:first-child,
.chart-item__active:first-child {
  margin: 0;
}

.chart-item__number {
  display: flex;
  position: relative;
  min-width: 54px;
  /* width: 56px; */
  height: 100%;
  background-color: #f1f3f8;
  color: #000;
  align-items: center;
  justify-content: center;
  border-radius: 5px 0 0 5px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  /* padding: 0 20px; */
}

.chart-item__number .chart-item__position {
  position: absolute;
  display: none;
  margin: 0;
  top: 5px;
  left: 5px;
}

.chart-item__preview,
.chart-item__preview_img-number {
  width: 68px;
  height: 68px;
  border-radius: 5px;
  object-fit: cover;
  max-width: none;
}

.chart-item__info {
  display: flex;
  border-radius: 5px;
  max-width: 100%;
  width: 100%;
  height: 100%;
}
.chart-item__time {
  position: relative;
  transform: translateY(-25%);
  right: 13px;
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  color: #000000;
}
.track-info {
  display: flex;
  flex-direction: column;
  width: auto;
}

.track-info__name {
  margin: 11px 0 0 18px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.track-info__author {
  color: #78797c;
  margin: 4px 0 0 18px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.chart-item__other-info {
  display: flex;
  /* margin: auto 0 12px auto; */
}

.chart-item__position {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto 24px 0 0;
}

.chart-item__position-change {
  display: flex;
  justify-content: center;
}

.chart-item__position-value {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
}

.chart-item__date {
  color: #78797c;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  margin: 36px 12px 0 0;
}

/* .chart-item__like {
  position: absolute;
  right: 12px;
  top: 26px;
} */

.chart-item__like:hover .line {
  stroke: #000000;
}

.chart-item__like.liked svg {
  fill: #7f7f7f;
  fill-opacity: 1;
}

.chart-item__like.liked .line {
  stroke: #7f7f7f;
}

.chart-item__like.liked:hover .line {
  stroke: #7f7f7f;
}

.chart-item__duration {
  margin: 38px 12px 0 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #78797c;
}

@media (max-width: 1200px) {
  .chart-item__date {
    display: none;
  }
}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
  /* .track-info {
    max-width: 180px;
    width: 100%;
  } */
  .chart-item__number {
    min-width: 54px;
    width: 54px;
  }

  /* .chart-item__preview_img-number {
    display: none;
  }

  .chart-item__position {
    display: none;
  } */
  .chart-item__number .chart-item__position {
    display: block;
  }

  .chart-item__control {
    display: none;
  }
}
