.quiz-container {
    width: 100%
}
.quiz-header {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 12px;
}

@media (min-width: 1200px) {
    .quiz-header {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 16px;
    }
}

.quiz-answers {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
    margin-bottom: 16px;
}

@media (min-width: 640px) {
    .quiz-answers {
        flex-direction: row;
        gap: 8px 23px;
    }
}

.quiz-answers .answer-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 14px 8px;
    background: #F1F3F8;
    border-radius: 5px;
    cursor: pointer;
}

.quiz-answers .answer-item:hover {
    background: #DAF3D2;
}
.quiz-answers .answer-item.active {
    background: #46C31F;
}

@media (min-width: 640px) {
    .quiz-answers .answer-item {
        width: calc(50% - 28px);
    }
}
@media (min-width: 1200px) {
    .quiz-answers .answer-item {
        width: calc(33% - 28px);
    }
}

.quiz-answers .answer-item .answer-title {
    font-size: 14px;
    line-height: 16px;
    color: #000;
    margin: 0;
    font-family: "Roboto", serif;
    font-size: 400
}

@media (min-width: 640px) {
    .quiz-answers .answer-item .answer-title {
        font-size: 16px;
        line-height: 18px;
    }
}
.button-accept {
    width: 100%;
    text-align: center;
    padding: 12px;
    border-radius: 5px;
    background: #7600AC;
    color: #fff;
    font-size: 16px;
    line-height: 18px;
    font-family: "Roboto", serif;
    font-weight: 900;
    border: unset;
    cursor: pointer;
}

.button-accept.hidden {
    display: none;
}

.quiz-answers .answer-item .answer-percent {
    font-size: 14px;
    line-height: 16px;
    color: #000;
    font-family: "Roboto", serif;
    font-size: 500
}
.quiz-answers .answer-item .answer-percent.hidden {
    display: none;
}
.quiz-answers .answer-item.active .answer-percent,
.quiz-answers .answer-item.active .answer-title {
    color: #fff;
}

@media (min-width: 640px) {
    .quiz-answers .answer-item .answer-percent {
        font-size: 18px;
        line-height: 21px;
    }
}