.chart-item {
    display: flex;
    position: relative;
    width: 100%;
    /* height: 68px; */
    margin: 12px 0 0 0;
    /* border-radius: 5px; */
    cursor: pointer;
}
.flyaway-chart .chart-item {
    width: calc(100% - 30px);
}
.chart_container {
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    margin: 12px 0 0;
}
.chart_container:hover {
    box-shadow: 0 1px 7px rgb(0 0 0 / 10%);
}

.chart_container.green:hover .chart-item__content,
.chart_container.green:hover .chart-item__number,
.chart_container.green:hover .chart-item__like,
.chart_container.green.active .chart-item__playtime {
    background-color: #daf3d2;
    transition: all 0.4s ease;
    /* box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1); */
}

.chart_container.green.active .chart-item__content,
.chart_container.green.active .chart-item__number,
.chart_container.green.active .chart-item__like,
.chart_container.green.active .chart-item__playtime {
    background-color: #60c427;
    color: #ffffff;
}

.chart_container.yellow:hover .chart-item__content,
.chart_container.yellow:hover .chart-item__number,
.chart_container.yellow:hover .chart-item__like {
    background-color: #fef5a3;
    transition: all 0.4s ease;
    /* box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1); */
}

.chart_container.yellow.active .chart-item__content,
.chart_container.yellow.active .chart-item__like {
    background-color: #ffe500;
}
.chart_container.yellow.active .chart-item__content .track-info span {
    color: #000;
}

.chart-item.yellow.active .chart-item__content,
.chart-item.yellow.active .chart-item__number {
    background-color: #ffe500;
    color: #000;
}

.chart-item.yellow.active .track-info__author {
    color: #000000;
}

.chart-item.purple:hover .chart-item__content,
.chart-item.purple:hover .chart-item__number,
.chart-item.purple:hover + .chart-item__like {
    background-color: #bba4d5;
    transition: all 0.4s ease;
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
}

.chart-item.purple.active .chart-item__content,
.chart-item.purple.active .chart-item__number {
    background-color: #7500ab;
    color: #ffffff;
}

.chart-item.green.active .track-info__author,
.chart-item.green.active .chart-item__date,
.chart-item.purple.active .track-info__author,
.chart-item.purple.active .chart-item__date {
    color: #ffffff;
}

.chart-item.purple.active .line,
.chart-item.green.active .line {
    stroke: #7f7f7f;;
}

.chart-item.active .chart-item__position path {
    fill: #ffffff;
}

.chart-item__content {
    display: flex;
    width: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    /* border-radius: 5px; */
}
.chart-item__like {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

/* .big-column__chart .chart-item__info {
    border-radius: unset;
} */

.chart-item__info {
    border-radius: 5px;
}

.chart_container.white {
    background-color: #ffffff;
}
.chart_container.grey {
    background-color: #f1f3f8;
}

.chart-item__preview-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chart-item__control {
    position: absolute;
    display: flex;
    width: 47px;
    height: 47px;
    justify-content: center;
    align-items: center;
}

/* .chart-item:first-child,
.chart-item__active:first-child {
    margin: 0;
} */
.chart_container:first-child {
    margin: 0;
}

.chart-item__number {
    display: flex;
    position: relative;
    min-width: 54px;
    /* width: 56px; */
    height: 68px;
    background-color: #f1f3f8;
    color: #000;
    align-items: center;
    justify-content: center;
    border-radius: 5px 0 0 5px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* padding: 0 20px; */
}

.chart-item__number .chart-item__position {
    position: absolute;
    display: none;
    margin: 0;
    top: 5px;
    left: 5px;
}

.chart-item__preview,
.chart-item__preview_img-number {
    /* width: 91px;
    height: 91px; */
    /* border-radius: 5px; */
    object-fit: cover;
    max-width: none;
}

.chart-item__info {
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    max-width: 100%;
    width: 100%;
    height: 100%;
}
.flyaway-chart .chart-item__info {
    width: calc(100% - 122px);
}
.playlist .chart-item__info {
    width: calc(100% - 68px);
}
.chart-item__time {
    position: relative;
    transform: translateY(-25%);
    right: 13px;
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    color: #000000;
}
.track-info {
    display: flex;
    flex-direction: column;
    /* width: auto; */
    
}
.flyaway-chart .track-info {
    width: calc(100% - 160px); /* - chart-item__other-info width */
}
.playlist .track-info {
    width: calc(100% - 52px); /* - chart-item__other-info width */
}
.new_songs .chart-item__info {
    width: calc(100% - 98px); /* - chart-item__other-info width */
}
.new_songs .track-info {
    width: 100%;
}
.playlist .chart-item__playtime {
    font-size: 16px;
    line-height: 18px;
    align-self: flex-end;
    font-weight: 300;
    color: rgba(0, 0, 0, .5);
    margin-right: 12px;
    margin-bottom: 12px;
}

.track-info__name {
    margin: 12px 0 0 12px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.track-info__author {
    color: #78797c;
    margin: 2px 0 0 12px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.track-info__name, .track-info__author{
    max-width: 100%;
    overflow: hidden;
}
.chart-item__other-info {
    display: flex;
    /* flex: 1 1; */
    align-items: center;
    justify-content: flex-end;
    /* margin: 0;
    margin: 0 auto; */
}
.chart-item__chart-position {
    display: flex;
    justify-content: center;
    width: 26px;
}
.chart-item__week {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* padding: 0 25px; */
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.5);
    /* margin: 4px 0 11px 18px; */
}
.chart-item__week.mobile {
    display: none;
}

.chart-item__like {
    /* position: absolute;
    right: 12px;
    top: 0;
    bottom: 0; */
    display: flex;
    align-items: center;
    cursor: pointer;
}

.chart-item__like:hover .line {
    stroke: #000000;
}

.chart-item__like.liked svg {
    fill: #7f7f7f;
    fill-opacity: 1;
}

.chart-item__like.liked .line {
    stroke: #7f7f7f;
}

.chart-item__like.liked:hover .line {
    stroke: #7f7f7f;
}

.chart-item__duration {
    margin: 38px 12px 0 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #78797c;
}

@media (max-width: 1850px) {
    /* .track-info__name, .track-info__author{
        max-width: 240px;
    } */
}
@media (max-width: 1770px) {
    /* .track-info__name, .track-info__author{
        max-width: 225px;
    } */
}
@media (max-width: 1730px) {
    /* .track-info__name, .track-info__author{
        max-width: 160px;
    } */
    .chart-item__week{
        font-size: 16px;
        /* max-width: 100px; */
    }
    .chart-item__number{
        padding: 0;
    }
}
@media (max-width: 1500px) {
    /* .track-info__name, .track-info__author{
        max-width: 130px;
    } */
}
@media (max-width: 1200px) {
    .chart-item__date {
        display: none;
    }
    .chart-item__week.desktop {
        display: none;
    }
    .chart-item__week.mobile {
        display: flex;
    }
    /* .chart-item__other-info {
        margin: 0 0 0 auto;
    } */
    .flyaway-chart .track-info {
        width: calc(100% - 26px); /* - chart-item__other-info width */
    }
}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
    /* .track-info {
        max-width: 180px;
        width: 100%;
    } */
    /* .chart-item__number {
        min-width: 50px;
        width: 50px;
    } */

    /* .chart-item__preview_img-number {
        display: none;
    }

    .chart-item__position {
        display: none;
    } */
    .chart-item__number .chart-item__position {
        display: block;
    }

    .chart-item__control {
        display: none;
    }
}
@media (max-width: 500px) {
    /* .chart-item__number {
        min-width: 35px;
        width: 35px;
    } */
}