/* .page-playlist .banner-container {
    padding: 0 25px;
    margin: 65px 0 0 0;
} */
.page-playlist .playlist-detail .chart-info__row {
    display: none;
}
.page-playlist .playlist-container {
    margin: 64px 0 0 0;
}
.page-playlist .playlist-container:first-child, .page-playlist .big-column__chart .playlist-container {
    margin: 0;
}
.page-playlist .big-column__chart .chart-scroll {
    display: none;
}
.big-column__chart_date-filter, .big-column__chart_time-filter {
    display: flex;
    justify-content: center;
    align-items: center;
}
.big-column__chart_date-filter {
    margin: 10px 0 48px;
}
.big-column__chart_time-filter {
    margin-bottom: 60px;
}
/* .date-filter_item.active {
    background: #46C31E;
}
.date-filter_item.active span{ 
    color: #fff;
}
.time-filter__item.active {
    background: #46C31E;
} */
.date-filter_item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 21px 18px 25px; */
    width: calc(100% / 7);
    border-radius: 5px;
    transition: all .3s;
}
.date-filter_day {
    color: #000000;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
}
.date-filter_date {
    color: #000000;
    padding-top: 6px;
    font-family: 'Roboto', sans-serif;
    font-size: 26px;
    line-height: 30px;
}
.time-filter__item_wrapper, .date-filter__item_wrapper {
    display: flex;
    overflow: hidden;
}
.time-filter__item_wrapper {
    width: calc(100% - (110px));
}
.time-filter__item {
    min-width: calc(100% / 8 - 3px);
    max-width: calc(100% / 8 - 3px);
    cursor: pointer;
    /* padding: 25px 9px; */
    margin-right: 3px;
    display: flex;
    align-items: center;
    background-color: #F2F4F8;
    border-radius: 5px;
    transition: all .3s;
    justify-content: center;
}
.time__filter_hour {
    color: #000000;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    line-height: 28px;
}
.time-scroll-left {
    margin-right: 25px;
}
.time-scroll-right {
    margin-left: 25px;
}
.active {
    background-color: #46C31E;
}
.active span {
    color: #FFFFFF;
}
.big-column__chart_time-filter + .chart-block {
    margin: 0;
}
.big-column__chart_time-filter + .chart-block .chart-info__row {
    display: none;
}
.swiper.swiper-initialized {
    width: 100%;
    overflow: hidden;
    margin: 0 55px;
}

.swiper-wrapper {
    display: flex;
    flex-direction: row;
}

@media (max-width: 1440px) {
    .time__filter_hour {
        font-size: 20px;
        line-height: 24px;
    }
}
@media (max-width: 1366px) {
    .time__filter_hour {
        font-size: 16px;
        line-height: 20px;
    }
}

@media (max-width: 1200px) {
    .time__filter_hour {
        font-size: 22px;
        line-height: 26px;
    }
    .page-playlist .big-column__chart {
        padding-top: 24px;
    }
}
@media (max-width: 640px) {
    .time__filter_hour {
        font-size: 18px;
        line-height: 22px;
    }
    .big-column__chart_date-filter {
        margin: 10px 0 20px;
    }
    .big-column__chart_time-filter {
        margin-bottom: 0px;
    }
}
@media (max-width: 480px) {
    .time-scroll-left {
        margin-right: 10px;
    }
    .time__filter_hour {
        font-size: 12px;
        line-height: 14px;
    }
    .time-scroll-right {
        margin-left: 10px;
    }
    .time-filter__item_wrapper {
        width: calc(100% - 80px);
    }
    .date-filter_day {
        font-size: 14px;
        line-height: 17px;
    }
    .date-filter_date {
        padding-top: 6px;
        font-size: 18px;
        line-height: 22px;
    }
}
/* @media (max-width: 375px) {
    .date-filter_day {
        font-size: 14px;
        line-height: 17px;
    }
    .date-filter_date {
        padding-top: 6px;
        font-size: 18px;
        line-height: 22px;
    }
    .time__filter_hour {
        font-size: 14px;
        line-height: 18px;
    }
} */

/* @media (max-width: 991px) {
    .page-playlist .banner-container {
        padding: 0;
        margin: 50px 0 0 0;
    }
} */

@media (max-width: 1199px) {
    .page-playlist .playlist-container {
      margin: 15px 0 0;
    }
  }
