.page__not-found {
  width: 100%;
  height: 100%;
  background-color: #131519;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: fixed;
}

.not-found__control {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  bottom: 100px;
}

.not-found__button {
  font-family: 'Roboto';
  margin: 0 auto;
  font-weight: 300;
  padding: 15px 30px;
  background-color: #ffffff;
  color: #0855f2;
  font-size: 20px;
  border-radius: 3px;
  text-decoration: none;
  margin: 0 auto;
}

.not-found__button:hover {
  background-color: #0855f2;
  color: #ffffff;
}

.not-found__alert {
  margin: 30px auto 0 auto;
  font-family: 'Roboto';
  color: #0855f2;
  font-size: 20px;
  font-weight: 300;
}
