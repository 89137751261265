.show-more-btn {
    width: 100%;
    height: 60px;
    background-color: #f1f3f8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 25px 0 0;
    cursor: pointer;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
}

.show-more-btn-text {
    margin: 0 15px 0 0;
}

.show-more-btn .arrow-purple__filled .circul {
    fill: #7500ab;
    stroke: #fff;
}
.show-more-btn .arrow-purple__filled .arrow {
    fill: #fff;
}

.show-more-btn:hover {
    background-color: #7500ab;  
}

.show-more-btn:hover .show-more-btn-text {
    color: #fff;
}

.show-more-btn:hover .arrow-purple__filled .circul {
    fill: #fff;
    stroke: #7500ab;
}
.show-more-btn:hover .arrow-purple__filled .arrow {
    fill: #7500ab;
}

@media (max-width:1200px) {
    .show-more-btn {
        margin: 12px 0 0;
    }
}