.content-block {
  display: flex;
  background-color: #fff;
  padding: 50px;
  box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.08);
  justify-content: space-between;
  margin: 32px 0 0 0;
}

@media (max-width: 1200px) {
  .content-block {
    flex-direction: column;
    box-shadow: 0 4px 45px rgba(0,0,0,.5);
    padding: 0 12px 25px 12px;
  }
}

@media (max-width: 767px) {
  .content-block {
    margin: 0;
    /* padding: 12px; */
  }
}
