/* .archive__scroll-bar {
  height: 964px;
  overflow: hidden;
  overflow-y: scroll;
  padding: 0 30px 0 0;
} */

.archive__scroll-bar {
  scrollbar-color: #7500ab #ffffff;
  scrollbar-width: thin;
}
.archive__scroll-bar::-webkit-scrollbar {
  width: 5px;
  background-color: #ffffff;
}

.archive__scroll-bar::-webkit-scrollbar-track {
  border: 2px solid #ffffff;
  background-color: #e3ccee;
}
.archive__scroll-bar::-webkit-scrollbar-thumb {
  background-color: #7500ab;
  border-radius: 10px;
}

@media (max-width: 1200px) {
  .archive-row {
    flex-direction: column;
  }
  .archive-row__link-to-back {
    margin: 10px 0;
  }
  .archive__scroll-bar {
    margin-top: 24px;
  }
}

@media (max-width: 991px) {
}

@media (max-width: 768px) {
}
