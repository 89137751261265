.chart-block {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chart-info__row {
  width: auto;
  height: 68px;
  display: flex;
  justify-content: space-between;
  /* padding: 0 25px; */
  width: 100%;
}

.chart-info {
  display: flex;
}

.chart-info__icon {
  height: 38px;
}

.chart-info__title {
  margin: 0 0 0 18px;
  color: #000;
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 37px;
}

.chart-info__title_link {
  text-decoration: none;
  color: #000;
}

.chart-info__title_link:not(.not-link):hover {
  color: #7500ab;
}

.chart-info__title_link.not-link {
  cursor:default;
  pointer-events: none;
}

.chart-scroll {
  display: flex;
}

.chart-scroll__up,
.chart-scroll__down {
  margin: 0 0 0 12px;
  width: 31px;
  height: 31px;
  background-position: center;
}

.chart-playlist {
  width: 100%;
  overflow: hidden;
}
/* .chart-playlist__scroll-bar {
  padding: 0 25px;
} */

@media (max-width: 1200px) {
  .chart-playlist__scroll-bar {
    padding: 0;
  }
  .chart-info__row {
    padding: 0;
    height: 38px;
    margin-bottom: 16px;
  }
  .chart-block {
    padding-top: 24px;
    margin: 0 !important;
  }
}

@media (max-width: 991px) {
  /* .chart-block {
    margin: 50px 0 0 0;
  } */
  .chart-block:first-child {
    margin: 0;
  }
}
