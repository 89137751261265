.form-mailing__container {
  width: 100%;
  /* max-width: 640px; */
  margin: 50px auto 0 auto;
}

.form-mailing {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 48px;
  background-color: #f1f3f8;
  border-radius: 5px;
}

.form-mailing__title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #000000;
}

.form-mailing__description {
  margin: 8px 0 0 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #78797c;
}

.form-mailing__input {
  width: 100%;
  height: 32px;
  margin: 12px 0 0 0;
  border: none;
  border-radius: 5px;
  background-color: #f8f9fc;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  padding: 0 0 0 18px;
}

.form-mailing__input::placeholder {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #c6c7ca;
}

.form-mailing__label {
  margin: 18px 0 0 0;
  display: flex;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.form-mailing__label:hover .form-mailing__checkbox[type='checkbox'] + div {
  border: 1px solid #727479;
}

.form-mailing__checkbox[type='checkbox'] {
  display: none;
}

.form-mailing__checkbox[type='checkbox'] + div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 16px;
  height: 16px;
  margin: 0 12px 0 0;
  background: #ffffff;
  border: 1px solid #ffffff;
  cursor: pointer;
}

.form-mailing__checkbox[type='checkbox'] + div svg {
  display: none;
}

.form-mailing__checkbox[type='checkbox']:checked + div svg {
  display: flex;
}

.form-mailing__submit-button {
  margin: 25px 0 0 0;
  padding: 13px 30px;
  background-color: #46c31e;
  border-radius: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  cursor: pointer;
}

.form-mailing input {
  outline: none;
}

.form-mailing button {
  border: none;
}

.form-mailing button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 991px) {
  .form-mailing__container {
    max-width: 100%;
  }
}
