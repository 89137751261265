.archive-item__link-container {
  margin: 12px 0 0 0;
  display: flex;
}
.archive-item__link-container:first-child {
  margin: 0;
}
.archive-item {
  display: flex;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
}
.background-grey {
  background-color: #f1f3f8;
}

.background-white {
  background-color: #ffffff;
}

.archive-item:hover {
  background-color: #daf3d2;
  transition: all 0.4s ease;
}

.archive-item__img-container {
  min-width: 120px;
  width: 120px;
  height: 120px;
}

.archive-item__img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.archive-item__content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0 24px 0 18px;
}

.archive-item__title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  font-weight: 500;
}

.archive-item__dates {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #7f7f7f;
  margin: 6px 0 0 0;
}

.archive-item__alert {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #E3093B;
}
@media (max-width: 1200px) {
  .archive-item__content {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 0 12px;
    justify-content: center;
  }
  .archive-item__dates {
    font-size: 14px;
    line-height: 16px;
  }
}
