.podcast-item {
  display: flex;
  height: 120px;
  margin: 6px 0 0 0;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.podcast-item:first-child {
  margin: 0;
}

.podcast-item.white {
  background-color: #ffffff;
}
.podcast-item.grey {
  background-color: #f1f3f8;
}

.podcast-item:hover {
  background-color: #daf3d2;
  cursor: pointer;
  transition: all 0.4s ease;
}

.podcast-item:hover .podcast-item__img-filter {
  transition: all 0.4s ease;
  background: rgba(70, 195, 30, 0.5);
}

.podcast-item__active:hover {
  background-color: #60c427 !important;
}
.podcast-item__active {
  background-color: #60c427 !important;
}

.podcast-item__img-container {
  border-radius: 5px;
  max-width: 120px;
  width: 100%;
  height: 120px;
  overflow: hidden;
}

.podcast-item__img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.podcast-item__img-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  position: absolute;
}

.podcast-item__control {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.podcast-item__control-single {
  width: 47px;
  height: 47px;
}

.podcast-item__control-double {
  display: flex;
  width: 100%;
  height: 100%;
}
.podcast-item__control-double_item {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.podcast-item:hover .podcast-item__control {
  opacity: 1;
  transition: all 0.4s ease;
}

.podcast-item__control-double_item svg{
  width: 47px;
  height: 47px;
}

.podcast-item__active .podcast-item__control {
  opacity: 1;
}

.podcast-item__info {
  margin: 0 18px 0 18px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.podcast-info__download-link {
  width: 25px;
}

.podcast-info__date {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #7f7f7f;
}

.podcast-item__active .podcast-info__date {
  color: #c7edbb;
}

.podcast-info__title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin: 10px 0 0 0;
}

.podcast-item__active .podcast-info__title {
  color: #ffffff;
}

.podcast-info__description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #78797C;
  margin: 10px 0 0 0;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.podcast-item__active .podcast-info__description {
  display: none;
}

.podcast-info__progress-slider {
  width: 430px;
} 

.podcast-info__timelines {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.podcast-info__timelines_numbers {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.7);
}

.podcast-info__audio-info {
  display: none;
  width: 100%;
  margin: 0 6px;
}

.podcast-info__control-container {
  display: flex;
  margin: 0 0 0 auto;
}

.podcast-item__active .podcast-info__audio-info {
  display: flex;
}

@media (max-width: 1200px) {
  .podcast-item__info {
    margin: 0 12px;
    width: calc(100% - 144px);
  }
  .podcast-info__title, .podcast-info__description {
    margin: 8px 0 0;
  }
  .podcast-info__description {
    font-size: 14px;
    line-height: 16px;
  }
}

@media (max-width: 480px) {
  .podcast-info__date {
    font-size: 14px;
    line-height: 16px;
  }
  .podcast-info__title {
    font-size: 16px;
    line-height: 18px;
  }
  .podcast-info__progress-slider .MuiSlider-root {
    padding: 7px 0;
  }
}
