.auto-swiper {
  width: 68px;
  height: 68px;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}

.auto-swiper .auto-swiper-item{
  margin: 0;
  display: none;
  transition: all 1s cubic-bezier(0.4, 0, 1, 1) 1s;
  object-fit: cover;
}

.auto-swiper .auto-swiper-item.active{
  display: block;
}

.program-now {
  display: flex;
  align-items: center;
}

.program-preview{
  width: 100px !important;
}

/* .flows-container {
  display: flex;
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.5);
  align-items: center;
}

.flows-item {
  position: relative;
  display: flex;
  margin: 0 25px;
  align-items: center;
  justify-content: center;
}

.flow-item__control {
  position: absolute;
  transition: all 0.3s ease 0s;
  opacity: 0;
}

.flows-item:hover,
.flows-item_active {
  color: #e40a3b;
}

.flows-item:hover .flow-item__control {
  opacity: 1;
}

.ether-player {
  display: flex;
}

.ether-player__logo-container {
  display: flex;
  height: 100%;
  width: auto;
}

.ether-player__logo-container:hover .ether-player__alert {
  display: flex;
}

.ether-player__alert {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 -146px;
  border-radius: 20px;
  width: 145px;
  height: 30px;
  color: #e40a3b;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(145, 145, 145, 0.25);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.ether-player__alert div {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #e40a3b;
  margin: 0 0 0 8px;
}
.ether-player__logo {
  cursor: pointer;
  height: 100%;
  max-width: none;
  width: auto;
  display: block;
}

.ether-player__preview-img {
  width: auto;
  height: 100%;
  margin: 0 0 0 24px;
  border-radius: 5px;
}
.ether-player__info {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 0 0 18px;
  font-size: 18px;
  line-height: 21px;
  font-family: 'Roboto';
  font-style: normal;
  white-space: nowrap;
}
.ether-player__info_track {
  display: flex;
  align-items: center;
  height: 50%;
  font-weight: bold;
  max-width: 300px;
}
.ther-player__info_author {
  display: flex;
  align-items: center;
  height: 50%;
  color: rgba(0, 0, 0, 0.5);
  font-weight: normal;
}

.player-burger {
  display: none;
}

@media (max-width: 1200px) {
  .flows-item_active {
    background-color: #e40a3b;
    color: white !important;
  }

  .ether-player__logo {
    height: 100%;
  }

  .ether-player {
    height: 100%;
    width: auto;
  }

  .ether-player__alert,
  .ether-player__preview-img,
  .ether-player__info,
  .ether-player__logo-container:hover .ether-player__alert {
    display: none;
  }

  .flows-container {
    position: fixed;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.9;
    margin: 0;
    border: none;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease 0s;
    padding: 64px 0 100px 0;
    color: white;
    z-index: 3;
  }

  .flows-item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .player-burger {
    cursor: pointer;
    display: block;
    position: relative;
    width: 30px;
    height: 20px;
    z-index: 3;
  }

  .player-burger.player-burger__active::before {
    transform: rotate(45deg);
    margin: 9px 0 0 0;
  }
  .player-burger.player-burger__active:after {
    transform: rotate(-45deg);
    margin: 0 0 9px 0;
  }
  .player-burger.player-burger__active span {
    transform: scale(0);
  }

  .player-burger:before,
  .player-burger:after {
    content: '';
    background-color: #e40a3b;
    width: 30px;
    height: 3px;
    position: absolute;
    left: 0;
    transition: all 0.3s ease 0s;
  }

  .player-burger span {
    background-color: #e40a3b;
    width: 30px;
    height: 3px;
    position: absolute;
    left: 0;
    top: 9px;
    transition: all 0.3s ease 0s;
  }
  .player-burger:before {
    top: 0;
  }
  .player-burger:after {
    bottom: 0;
  }

  .flows-container.player-burger__active {
    top: 0;
  }
  .flow-item__control {
    display: none;
  }
}

@media (max-width: 992px) {
}

@media (max-width: 767px) {
} */



