.programs-item {
  position: relative;
  /* width: 694px; */
  /* height: 318px; */
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
}
.block-programs-item {
  margin: 36px 0 0;
  width: 100%;
  overflow: hidden;
}
.programs-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.programs-item__inner-row {
  position: absolute;
  bottom: 0;
  /* height: 98px; */
  color: #ffffff;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
  width: 100%;
}
.programs-item__inner-row-content {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 28px 14px 28px;
  height: 100%;
}
.programs-item__title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 37px;
  color: #FBE64D;
}
.programs-item__description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}
.programs-item__timetable {
  padding: 10px;
  border: 1px solid #ffffff;
  border-radius: 8px;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.timetable-item {
  white-space: nowrap;
}
@media (max-width: 1440px) {
  .programs-item__inner-row-content {
    padding: 0 15px 10px 15px;
  }
  .programs-item__title {
    font-size: 22px;
    line-height: 26px;
  }
  .programs-item__description {
    font-size: 18px;
    line-height: 22px;
  }
}
@media (max-width: 1200px) {
  .block-programs-item {
    margin: 12px 0 0;
    height: unset;
  }
  .programs-item__inner-row-content {
    align-items: flex-end;
  }
  /* .programs-item__inner-row-content {
    display: flex;
    padding: 0 12px 5px;
  } */
  .programs-item__title {
    font-size: 14px;
    line-height: 16px;
    color: #ffe500;
  }
  .programs-item__description {
    font-size: 12px;
    line-height: 16px;
  }
  .programs-item__timetable {
    padding: 6px 12px;
    width: unset;
  }
  .timetable-item {
    font-size: 14px;
    line-height: 16px;
  }
}
