.page-main .playlist-container {
  margin: 64px 0 0 0;
}
.page-main .playlist-container:first-child {
  margin: 0;
}

/* .page-main .footer-block {
  max-height: 335px;
} */

@media (max-width: 991px) {
  .page-main .footer-block {
    max-height: none;
  }
  /* .page-main .small-column {
    margin: 50px 0 0 0;
  } */
}

@media (max-width: 1199px) {
  .quiz-container {
    padding-top: 25px;
  }
}
