.page-music .playlist-container {
  margin: 64px 0 0 0;
}
.page-music .playlist-container:first-child {
  margin: 0;
}
.page-music .big-column__chart .chart-scroll {
  display: none;
}

@media (max-width: 1199px) {
  .page-music .playlist-container {
    margin: 15px 0 0;
  }
}
