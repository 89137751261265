.current-offer__container.margin-top {
  margin-top: 26px;
}
.current-offer__image img{
  width: 100%;
}
.current-offer__dates{
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #78797C;
}
.current-offer__title {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  font-size: 32px;
  line-height: 37px;
  color: #7500ab;
  margin: 0 0 12px;
}

.current-offer__google-form {
  margin: 30px auto 0;
  max-width: 520px;
  padding: 5px;
  box-shadow: 0 5px 10px #454647, inset 1px 0 6px #454647;
  border-radius: 5px;
}

.content-editor iframe {
  width: 100% !important;
}

@media (max-width: 991px) {
  .page-offers .small-column {
    margin: 50px 0 0 0;
  }
}
