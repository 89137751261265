.item-news {
  position: relative;
  width: 343px;
  /* height: 270px; */
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  cursor: pointer;
  /* margin: 0 20px 20px 0; */
}

.item-news__wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-news__row {
  position: absolute;
  bottom: 0;
  /* height: 98px; */
  color: #ffffff;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
  width: 100%;
  border-radius: 5px;
}
.item-news__row-content {
  padding: 15px;
}

.item-news__row-date {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffe500;
}

.item-news__row-title {
  margin: 7px 0 0 0;
  max-height: 42px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}

@media (max-width: 640px) {
  .item-news__row-content {
    padding: 8px;
  }
  .item-news__row-title {
    font-size: 14px;
    line-height: 18px;
  }
}
