html {
  background-attachment: fixed;
  overflow-x: hidden;
  overflow-y: scroll;
  /* margin-right: calc(-1 * (100vw - 100%)); */
}

body {
  background-size: cover;
  background-attachment: fixed;
}

.zoom-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
}
.zoom-item {
  transform: translate(0, 0) scale(1.01) translateZ(0);
  transition: transform 0.5s ease-out;
}
.zoom-container:hover .zoom-item {
  transform: translate(0, 0) scale(1.08);
  transition: transform 0.8s cubic-bezier(0.375, 0.435, 0.41, 0.75);
  /* transform: scale(1.1, 1.1); */
}

.content-editor {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  margin-top: 12px;
}
.content-editor a {
  color: #7500ab !important;
}
.content-editor a:hover {
  color: #46c31e !important;
}
.content-editor li {
  list-style-type: square;
}
.content-editor li::marker {
  color: #46c31e;
}
.content-editor p {
  margin: 0;
}

.content-editor iframe {
  border: none;
}
