@-webkit-keyframes hvr-push {
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes hvr-push {
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.hvr-push {
  -webkit-animation-name: hvr-push;
  animation-name: hvr-push;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

@keyframes track-animation {
  0% {
    -moz-perspective: 0px;
    -webkit-perspective: 0px;
    perspective: 0px;
    -moz-transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    z-index: 100;
  }
  2% {
    -moz-perspective: 300px;
    -webkit-perspective: 300px;
    perspective: 300px;
    -moz-transform: rotateX(180deg);
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
    transform: perspective(300px) rotateX(180deg);
    z-index: 100;
  }
  100% {
    -moz-perspective: 0px;
    -webkit-perspective: 0px;
    perspective: 0px;
    -moz-transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    z-index: 0;
  }
}

.track-animation {
  animation: track-animation 0.5s forwards ease-out alternate;
}

@media (max-width: 1200px) {
  .track-animation {
    animation: none;
  }
}
