.page-about .about-content {
  margin: 0 0 12px 0;
}
.page-about .emcee-item:first-child {
  margin: 0;
}
.about-row {
  display: flex;
  height: 68px;
}
.page-about .about-row__icon {
  width: 38px;
  height: 38px;
}
.page-about .about-row__title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 37px;
  color: #000000;
  margin: 0 0 0 18px;
}

/* .about-content div:nth-child(2) li {
  list-style-type: disc;
}
.about-content div:nth-child(2) li::marker {
  color: #7500AB;
} */
.page-about h1 {
  font-family: 'Roboto';
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  /* padding: 42px 0 24px; */
}
.page-about h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding-bottom: 20px;
}
.page-about ul {
  padding-bottom: 24px;
  padding-left: 8px;
}
.page-about li {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  padding-bottom: 12px;
  /* marker-start: none; */
  list-style-type: none;
  position: relative;
}
.page-about li:last-child {
  padding-bottom: 0;
}
.page-about li::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  top: 8px;
  left: -8px;
  display: block;
  border-radius: 50%;
  background-color: #46c31e;
}
/* .page-about li::marker {
  color: #46c31e;
} */
.page-about p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
}
.page-about .programs-item {
  /* width: 471px; */
  /* height: 218px; */
}
.stations li {
  list-style-type: disc;
}

.block-programs__more-button {
  max-width: 471px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 22px;
  padding: 17px 0;
  background-color: rgba(229, 233, 242, 0.5);
  border-radius: 5px;
}
.block-programs__more-button:active {
  color: #7500ab;
}
.block-programs__more-button span {
  padding-right: 27px;
  text-decoration: underline;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  line-height: 26px;
}

.page-about .small-column__chart .playlist-container:not(:first-child) {
  margin: 64px 0 0;
  }

@media (max-width: 1200px) {
  .about-row {
    height: unset;
  }
  .page-about h1 {
    padding: 0;
    margin: 24px 0 12px 0;
  }
  .page-about h2 {
    padding: 0;
    margin: 0 0 12px 0;
  }
  .page-about ul {
    padding-bottom: 0px;
    margin-bottom: 0;
  }
  .page-about .small-column__chart .playlist-container:not(:first-child) {
    margin: 15px 0 0;
  }
}
